.card-social {
  .card-body {
    padding: 1.875rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.card-facebook {
  background: $facebook;
  &:hover {
    background: lighten($facebook, 10%);
  }
}
.card-twitter {
  background: $twitter;
  &:hover {
    background: lighten($twitter, 10%);
  }
}
.card-instagram {
  background: $instagram;
  &:hover {
    background: lighten($instagram, 10%);
  }
}
.card-dribbble {
  background: $dribbble;
  &:hover {
    background: lighten($dribbble, 10%);
  }
}