.trello {
  overflow: hidden;
  .mdk-drawer-layout,
  .mdk-header-layout,
  .mdk-header-layout__content {
    height: 100%;
  }
  .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 0;
  }
  &-container {
    flex: 1 1 0%;
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &-board {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100%;

    &__tasks {
      display: inline-flex;
      align-items: start;
      width: 22rem;
      height: 100%;

      &:not(:last-child) {
        margin-right: 1.25rem;
      }

      .card {
        width: 100%;
        max-height: calc(100% - 1.5625rem);
        .card-header {
          border-width: 2px;
        }
      }

      .card-body {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }

      &-list {
        min-height: 10px;
      }

      &-item {
        margin-bottom: $card-spacer-x/2;
        border-color: rgba($body-color, .1) !important;
        &:hover {
         background: rgba($body-color, .03); 
        }
      }
    }
  }
}