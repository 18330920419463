@each $size in (1, 2, 3, 4) {
  .border-#{$size} {
    border: #{$size}px solid $border-color !important;
  }
  @each $position in (left, right, top, bottom) {
    .border-#{$position}-#{$size} {
      border-#{$position}: #{$size}px solid $border-color !important;
    }
    .border-#{$position}-#{$size} {
      border-#{$position}: #{$size}px solid $border-color !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}         { border: $border-width solid $border-color !important; }
    .border-top#{$infix}     { border-top: $border-width solid $border-color !important; }
    .border-right#{$infix}   { border-right: $border-width solid $border-color !important; }
    .border-bottom#{$infix}  { border-bottom: $border-width solid $border-color !important; }
    .border-left#{$infix}    { border-left: $border-width solid $border-color !important; }

    .border#{$infix}-0        { border: 0 !important; }
    .border-top#{$infix}-0    { border-top: 0 !important; }
    .border-right#{$infix}-0  { border-right: 0 !important; }
    .border-bottom#{$infix}-0 { border-bottom: 0 !important; }
    .border-left#{$infix}-0   { border-left: 0 !important; }

    .rounded#{$infix} {
      border-radius: $border-radius !important;
    }
    .rounded-top#{$infix} {
      border-top-left-radius: $border-radius !important;
      border-top-right-radius: $border-radius !important;
    }
    .rounded-right#{$infix} {
      border-top-right-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
    }
    .rounded-bottom#{$infix} {
      border-bottom-right-radius: $border-radius !important;
      border-bottom-left-radius: $border-radius !important;
    }
    .rounded-left#{$infix} {
      border-top-left-radius: $border-radius !important;
      border-bottom-left-radius: $border-radius !important;
    }

    .rounded#{$infix}-0 {
      border-radius: 0 !important;
    }
    .rounded-top#{$infix}-0 {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
    .rounded-left#{$infix}-0 {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    .rounded-right#{$infix}-0 {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
  @each $position in (left, right, top, bottom) {
    .border-#{$position}-#{$color} {
      border-#{$position}-color: $value !important;
    }
  }
}