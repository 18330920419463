.dp-preview {
  position: relative;
  z-index: 0;
  text-decoration: none;
  display: block;
  margin-bottom: 1.5rem;
  border-radius: $border-radius-lg;
  overflow: hidden;

  &__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    content: '';
    background-color: rgba($primary, .9);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .25s ease;
    z-index: 1;
  }

  @include hover {
    .dp-preview__overlay {
      opacity: 1;
    }
  }
}