.fc button {
  height: auto;
}
.fc td {
  padding: 0 !important;
}
.fc-event {
  line-height: 1.5;
  border-color: transparent;
  border-radius: $border-radius;
  padding: .25rem .5rem;
}
.fc-title {
  font-weight: 600;
}
.fc-popover .fc-header {
  position: relative;
  padding: .25rem .5rem;
  .fc-close {
    position: absolute;
    top: .6rem;
    right: .5rem;
  }
}

.external-event {
  padding: .25rem .5rem;
  color: white;
  margin-bottom: .5rem;
  display: flex;
  border-radius: $border-radius;
  cursor: move;
}