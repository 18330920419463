@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix} {
      flex: 1 1 0% !important;
    }
    .flex#{$infix}-none,
    .flex#{$infix}-0 {
      flex: none !important;
    }
    .flex#{$infix}-grow {
      flex: 1 1 auto !important;
    }
    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }
  }
}