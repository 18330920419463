.dashboard-location-tabs {
  &__tab {
    margin-bottom: $grid-gutter-width/3;
    cursor: pointer;

    &:not(.active) {
      .progress-bar {
        background: $text-muted !important;
      }
    }
  }
}