.list-skills {
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    > div + div {
      padding-left: 1rem;
    }
    > div:first-child {
      width: 110px;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}